import React from 'react'
import Layout from '../components/Layout'

function Contact() {
  return (
    <Layout>
      <div className="w-screen bg-primary py-40">
        <h1 className="text-7xl text-white text-center uppercase tracking-wider font-heading">
          Contact Us
        </h1>
      </div>
      <div className="container mx-auto py-20">
        <div className="grid place-items-center">
          <div className="w-11/12 p-12 bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
            {/* <h1 class="text-xl font-semibold">Hello there 👋, <span class="font-normal">please fill in your information and leave a message</span></h1> */}
            <form className="mt-6" method="post" name="contact" data-netlify="true" action="/thanks">
            <input type="hidden" name="contact" value="contact" />
              <div className="flex justify-between gap-3">
                <span className="w-1/2">
                  <label for="firstname" className="block text-xs font-semibold text-gray-600 uppercase">Firstname</label>
                  <input id="firstname" type="text" name="firstname" placeholder="John" autocomplete="given-name" className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                </span>
                <span class="w-1/2">
                  <label for="lastname" className="block text-xs font-semibold text-gray-600 uppercase">Lastname</label>
                <input id="lastname" type="text" name="lastname" placeholder="Doe" autocomplete="family-name" className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                </span>
              </div>
              <label for="email" className="block mt-2 text-xs font-semibold text-gray-600 uppercase">E-mail</label>
              <input id="email" type="email" name="email" placeholder="john.doe@company.com" autocomplete="email" className="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />

              <label for="message" className="block mt-2 text-xs font-semibold text-gray-600 uppercase">Message</label>
              <textarea id="message" className="block w-full p-3 mt-2 bg-gray-200 text-gray-700 focus:outline-none focus:bg-gray-300 focus:shadow-inner h-60"></textarea>
              
              <button type="submit" className="w-full py-3 mt-6 tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none font-heading">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
